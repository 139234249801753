import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant='h1' sx={styles.heading}>
      Learn to love your home.
    </Heading>
    <Heading variant='h2' sx={styles.running}>
      "The light is what guides you home, the warmth is what keeps you there..."
    </Heading>
    <Heading variant='h3'>Ellie Rodriguez</Heading>
  </Box>
)
