import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        We take time and care in writing all about home improvement.
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        We also share our favorite products and services.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button variant='white' as={Link} to='/authors'>
        Our Authors
      </Button>
    </Box>
  </>
)
